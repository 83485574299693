<template>
  <div
    v-if="periods.length > 0"
    class="relative p-4"
    :style="`background-image: url(${banner_url})`"
  >
    <div class="hero__overlay"></div>
    <div
      v-for="(period, periodKey) in periods"
      :key="periodKey"
      class="max-w-xl rounded-t-lg rounded-lg relative z-10 container mx-auto"
    >
      <p
        class="text-xl md:text-2xl font-bold rounded-t-lg p-4 uppercase tracking-wider text-green-50"
      >
        Pengumuman kelulusan Peserta Didik {{ period.period_name }}
      </p>
      <p class="p-4 text-white uppercase tracking-wider">
        Total Peserta Lulus:
        <span class="font-bold"> {{ period.students.length }} Orang </span>
      </p>

      <!-- Table -->
      <div
        class="bg-white p-2 shadow-lg mb-4"
        style="max-height: 400px; overflow-y: auto"
        v-if="period.students"
      >
        <table id="table" class="table-auto border-collapse mt-2 w-full">
          <thead>
            <tr class="text-left border-b border-gray-200">
              <th class="w-10 text-center">No.</th>
              <th>Nama</th>
            </tr>
          </thead>
          <tbody class="relative">
            <tr v-for="(item, key) in period.students" :key="`student-${key}`">
              <td class="text-center">{{ key + 1 }}</td>
              <td>{{ item }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    periods: {
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      banner_url: `${process.env.VUE_APP_API_URL}/storage/main/main-banner.jpg`,
    };
  },
  name: "AcceptanceAnnouncement",
};
</script>

<style lang="scss" scoped>
.hero__overlay {
  background-image: linear-gradient(to bottom, #3da544ee, #3da544ee);

  @apply absolute h-full w-full top-0 left-0;
}

#table {
  th,
  td {
    @apply px-4 py-2;
  }
}
</style>